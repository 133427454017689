document.addEventListener('DOMContentLoaded', function () {
  // Your JavaScript code here
  const experienceElement = document.getElementById('experience');
  const aboutMeElement = document.getElementById('about-me');
  const experienceBlock = document.getElementById('experience_block');
  const aboutBlock = document.getElementById('about_block');

  experienceElement.addEventListener('click', function () {
    if (!experienceElement.classList.contains('active')) {
      experienceElement.classList.add('active');
      aboutMeElement.classList.remove('active');
      experienceBlock.style.display = 'block';
      aboutBlock.style.display = 'none';
    }
  });

  aboutMeElement.addEventListener('click', function () {
    if (!aboutMeElement.classList.contains('active')) {
      aboutMeElement.classList.add('active');
      experienceElement.classList.remove('active');
      aboutBlock.style.display = 'block';
      experienceBlock.style.display = 'none';
    }
  });
});
